.holder {
     background: #fff;
     width: 100vw;
     height: 100vh;
     display: flex;
     justify-content: center;
     align-items: center;
     margin: 0;
     padding: 0
 }

 .spinner {
     width: 40px;
     height: 40px;
     position: relative;
     text-align: center;
     -webkit-animation: sk-rotate 2s infinite linear;
     animation: sk-rotate 2s infinite linear
 }

 .dot1,
 .dot2 {
     width: 60%;
     height: 60%;
     display: inline-block;
     position: absolute;
     top: 0;
     background-color: #007eb8;
     border-radius: 100%;
     -webkit-animation: sk-bounce 2s infinite ease-in-out;
     animation: sk-bounce 2s infinite ease-in-out
 }

 .dot2 {
     top: auto;
     bottom: 0;
     -webkit-animation-delay: -1s;
     animation-delay: -1s
 }

 @-webkit-keyframes sk-rotate {
     100% {
         -webkit-transform: rotate(360deg)
     }
 }

 @keyframes sk-rotate {
     100% {
         transform: rotate(360deg);
         -webkit-transform: rotate(360deg)
     }
 }

 @-webkit-keyframes sk-bounce {

     0%,
     100% {
         -webkit-transform: scale(0)
     }

     50% {
         -webkit-transform: scale(1)
     }
 }

 @keyframes sk-bounce {

     0%,
     100% {
         transform: scale(0);
         -webkit-transform: scale(0)
     }

     50% {
         transform: scale(1);
         -webkit-transform: scale(1)
     }
 }
