/* index.css or globals.css */
.text-gradient {
    background: linear-gradient(to right, #10B981, #1D4ED8);
    /* Green to Blue */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* index.css or globals.css */
@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
    }
}

@keyframes compress {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.95);
    }
}

.bounce {
    animation: bounce 1s infinite;
}

.compress {
    animation: compress 1s infinite;
}
.dot{
    animation:  backgroundChange 1s infinite;
}
/* index.css or globals.css */
@keyframes moveToBottom {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(calc(100vh - 100%));
    }
}

@media (max-width: 768px) {
    .move-to-bottom {
        animation: moveToBottom 2s ease-in-out forwards;
    }
}
@keyframes backgroundChange{
    from {
        background-color:#b1c691 ;
    }
    to {
        background-color: blue;
    }
}

section{
    margin-left: 10px;
}

.contact-container {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    /* Adjust the height as needed */
    background: url('./resources/images/contact/prism.jpg.webp') no-repeat center center/cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blurred-frame {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    position: relative;
   
    border-radius: 10px;
    padding: 20px;
    min-width: 80%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.auth {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    /* Adjust the height as needed */
    background: url('./resources/images/auth/blue.jpg') no-repeat center center/cover;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* Slide in animations */
@keyframes slideInFromTop {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

/* Slide out animations */
@keyframes slideOutToRight {
    from {
        transform: translateX(0);
        opacity: 1;
    }

    to {
        transform: translateX(100%);
        opacity: 0;
    }
}

@keyframes breakOut {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.2);
        opacity: 0.5;
    }

    100% {
        transform: scale(0);
        opacity: 0;
    }
}


/* styled card */

.card {
    position: relative;
    overflow: hidden;
}

.ripple {
    position: absolute;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: gray;
    animation: ripple-animation 0.6s linear;
}

@keyframes ripple-animation {
    from {
        transform: scale(1);
        opacity: 1;
    }

    to {
        transform: scale(100);
        opacity: 0;
    }
}

main{
    min-height: 100vh;
    padding-bottom: 0;
    margin: 0;
    min-width: 100%;
}